













import { Component, Vue } from 'vue-property-decorator';
import GeneralCrudList from '@/components/common/GeneralCrudList.vue';
import { generalDataService } from '@/services/GeneralDataService';
import { manualDataConfigService } from '@/services/ManualDataConfigService';

@Component({
  components: { GeneralCrudList },
})
export default class ReportsConfiguration extends Vue {
  model: any = [
    { name: 'name', label: 'Nazwa', type: 'text' },
    {
      name: 'type',
      label: 'Typ',
      type: 'select',
      options: [
        { id: 1, name: 'Kategoria A' },
        { id: 2, name: 'Parametr A' },
        { id: 3, name: 'Dane Manualne A' },
        { id: 4, name: 'Marża' },
        { id: 5, name: 'Marża %' },
        { id: 6, name: 'Wskaźnik Kategoria A / Kategoria B' },
        { id: 7, name: 'Wskaźnik Kategoria A / Parametr A' },
        { id: 8, name: 'Wskaźnik Kategoria A / Manualne A' },
        { id: 9, name: 'Wskaźnik Parametr A / Kategoria A' },
        { id: 10, name: 'Wskaźnik Parametr A / Parametr B' },
        { id: 11, name: 'Wskaźnik Parametr A / Manualne A' },
        { id: 12, name: 'Wskaźnik Manualne A / Kategoria A' },
        { id: 13, name: 'Wskaźnik Manualne A / Parametr A' },
        { id: 14, name: 'Wskaźnik Manualne A / Manualne B' },
        { id: 15, name: 'Suma' },
      ],
    },
    { name: 'valueCategoryA', label: 'Kategoria A', type: 'select', options: [] },
    { name: 'valueCategoryB', label: 'Kategoria B', type: 'select', options: [] },
    { name: 'valueParamA', label: 'Parametr A', type: 'select', options: [] },
    { name: 'valueParamB', label: 'Parametr B', type: 'select', options: [] },
    { name: 'valueManualA', label: 'Dane Manualne A', type: 'select', options: [] },
    { name: 'valueManualB', label: 'Dane Manualne B', type: 'select', options: [] },
    { name: 'modCategoryA', label: 'Modyfikator Kategorii A', type: 'text' },
    { name: 'modCategoryB', label: 'Modyfikator Kategorii B', type: 'text' },
    { name: 'modParamA', label: 'Modyfikator Parametru A', type: 'text' },
    { name: 'modParamB', label: 'Modyfikator Parametru B', type: 'text' },
    { name: 'modManualA', label: 'Modyfikator Danych Manualnych A', type: 'text' },
    { name: 'modManualB', label: 'Modyfikator Danych Manualnych B', type: 'text' },
  ];
  listOptions: any = [
    { field: 'name', label: 'Nazwa' },
    {
      field: 'type',
      label: 'Typ',
      options: {
        1: 'Kategoria',
        2: 'Parametr',
        3: 'Dane Manualne',
        4: 'Marża',
        5: 'Marża %',
        6: 'Wskaźnik Kategoria/Kategoria',
        7: 'Wskaźnik Kategoria/Parametr',
        8: 'Wskaźnik Kategoria/Manualne',
        9: 'Wskaźnik Parametr/Kategoria',
        10: 'Wskaźnik Parametr/Parametr',
        11: 'Wskaźnik Parametr/Manualne',
        12: 'Wskaźnik Manualne/Kategoria',
        13: 'Wskaźnik Manualne/Parametr',
        14: 'Wskaźnik Manualne/Manualne',
        15: 'Suma',
      },
    },
  ];
  mounted() {
    generalDataService
      .list('generalList/financialCategory')
      .then((data: any) => {
        this.model[2].options = [];
        this.model[3].options = [];
        data.map((item: any) => {
          this.model[2].options.push(item);
          this.model[3].options.push(item);
        });
      })
      .catch(() => {});

    generalDataService
      .list('generalList/parameter')
      .then((data: any) => {
        this.model[4].options = [];
        this.model[5].options = [];
        data.map((item: any) => {
          this.model[4].options.push(item);
          this.model[5].options.push(item);
        });
      })
      .catch(() => {});

    manualDataConfigService
      .list()
      .then(data => {
        this.model[6].options = [];
        this.model[7].options = [];
        data.map((item: any) => {
          this.model[6].options.push(item);
          this.model[7].options.push(item);
        });
      })
      .catch(() => {});
  }
}
