import { httpService } from '@/services/HttpService';

class ManualDataConfigService {
  update(id: number, name: string, fields: string, type: number): Promise<any> {
    return httpService.post('manualDataConfiguration/${id}', { name, fields, type }, {});
  }
  add(name: string, fields: string, type: number): Promise<any> {
    return httpService.post(`manualDataConfiguration`, { name, fields, type }, {});
  }
  list(): Promise<any> {
    return httpService.get('manualDataConfiguration', {}, {});
  }
}

export const manualDataConfigService = new ManualDataConfigService();
